
export const namespaced = true 
import { comp_play_error_file, comp_play_success_file } from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {

    state : {
        subscription_plans  :'', 
    },
    
    getters : {
        subscription_plans : (state )=> state.subscription_plans ,
    },


    mutations : { 
        setsubscription_plans  :(state,subscription_plans  ) => (state.subscription_plans  = subscription_plans ), 
    },

    actions : {

        async fetch_subscription_plans( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'fetch_subscription_plans')
            return new Promise((resolve, reject) => {
            axios.post("/settings/hospital/subscription/plans", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsubscription_plans', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        } 
                    }
                }
                reject(error)
            });
            });	       
        },

        async pay_and_update_subscription_status( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'pay_and_update_subscription_status')
            return new Promise((resolve, reject) => {
            axios.post("/settings/hospital/subscription/subscribe", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                    commit('sethospital', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        } 
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_hospital_details( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_hospital_details')
            return new Promise((resolve, reject) => {
            axios.post("/settings/hospital/info", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('sethospital', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        } 
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_hospital_logo( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_hospital_logo')
            return new Promise((resolve, reject) => {
            axios.post("/settings/hospital/logo/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('sethospital', response.data.data)
                comp_play_success_file('Logo has been Updated Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        } 
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_hospital_details( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_hospital_details')
            return new Promise((resolve, reject) => {
            axios.post("/settings/hospital/update/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('sethospital', response.data.data)
                comp_play_success_file('Information has been Updated Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        } 
                    }
                }
                reject(error)
            });
            });	       
        },

        async async_utility_database( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'async_utility_database')
            return new Promise((resolve, reject) => {
            axios.post("/settings/hospital/sync_utility", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                comp_play_success_file('Data Synced Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        } 
                    }
                }
                reject(error)
            });
            });	       
        },
        
    }
}
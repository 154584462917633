export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        pos_items :'', 
        pos_item :'', 
    },
    
    getters : {
        pos_items: (state )=> state.pos_items,
        pos_item: (state )=> state.pos_item,
    },


    mutations : { 
        setpos_items :(state,pos_items ) => (state.pos_items = pos_items), 
        setpos_item :(state,pos_item ) => (state.pos_item = pos_item),

    },

    actions : {

        async issue_and_forward_pos_case( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'issue_and_forward_pos_case')
            return new Promise((resolve, reject) => {
            axios.post("/pos/create", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                toast.success("Request has been Created and Forwared Successfuly")
                }
                resolve(response)
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },

        async issue_case_and_payout( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'issue_case_and_payout')
            return new Promise((resolve, reject) => {
            axios.post("/pos/payout", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                toast.success("Request has been Created and Forwared Successfuly")
                }
                resolve(response)
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },
  
      
    }


}